export function is_finished(item) {
  var real_date_start = new Date(Date.parse(item.date_start));
  real_date_start.setHours(item.time_start.split(':')[0])
  real_date_start.setMinutes(item.time_start.split(':')[1])
  var real_date_stop = new Date(Date.parse(item.date_end));
  real_date_stop.setHours(item.time_stop.split(':')[0])
  real_date_stop.setMinutes(item.time_stop.split(':')[1])
  var currDate = new Date();

  if (currDate > real_date_start) {
    if (currDate < real_date_stop) {
      return 0;
    }
  }
  if (currDate < real_date_start) {
    return 3;
  }
  return 1;
}
export function is_paid(item) {
  return item.paid_amount >= item.total;
}
export function getColor(item) {
  let _is_finished = is_finished(item);
  let _is_paid = is_paid(item);
  if (!_is_finished && !_is_paid) return 'red';
  if (!_is_finished) return 'orange';
  if (_is_finished == 3) return 'blue';
  else return 'green';
}
export function is_sa(item) {
  return item.sa > -1 ? item.sa : (parseFloat(item.vat) === 8.1 | 0)
}
export function topay(item) {
  if (item == null)
    return 0
  return parseFloat(item.total) + parseFloat(item.after_extra_total == '' ? 0.0 : item.after_extra_total) - parseFloat(item.amount_paid);
}
export function share(item) {
  var params = window.location.origin + '/portal/' + item.uuid;
  if (navigator.share)
  {
    navigator.share({ title: "Riviera Rentals", url: params});
  }
  else
  {
    window.location.replace(params);
  }
}
