import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import './registerServiceWorker'

Vue.config.productionTip = false;
Vue.prototype.$mywindow = window;

var vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

//global.vm = vm;