<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-1"
      :search="search"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, 500, -1]
      }"
      :custom-filter="filterOnlyCapsText"
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <template v-slot:top>
        <v-text-field
        
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          class="mx-3 pt-3"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:[`item.contract`]="{ item }">
        <v-btn small :to="{ name: 'newcontract',  params: { clientid: item.id }}">New contract</v-btn>
      </template>
      <template v-slot:[`item.telephone`]="{ item }">
        <a :href="'tel:'+item.telephone">{{ item.telephone}}</a>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <a :href="'mailto:'+item.email">{{ item.email}}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DataService from "../services/DataService";

export default  {

    name: 'ClientList',
    components: {
      
    },
    data () {
      return {
        search: '',
        items:[]
      }
    },
    computed: {
      headers () {
        return [
          { text: 'ID', value: 'id' },
          { text: 'First name', value: 'firstname' },
          { text: 'Last name', value: 'lastname' },
          { text: 'Country', value: 'country' },
          { text: 'City', value: 'city' },
          { text: 'Telephone', value: 'telephone' },
          { text: 'Email', value: 'email' },
          { text: 'Birthdate', value: 'birthdate' },
          { text: 'ID exp', value: 'expiration' },
          { text: 'Contract', value: 'contract' },
        ]
      },
    },
    methods: {
      numberFormat(number, width) {
            return new Array(+width + 1 - (number + '').length).join('0') + number;
      },
      filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      },
      getColor (my_bool) {
        if (my_bool) return 'red';
        else return '';
      },
    },

    async mounted()
    {
        if (localStorage.getItem("password") === null) {
            this.$router.push({ name: 'login' });
        } 
        var res = await DataService.getclients();
        console.log(res);
        this.items = res.data.clients;
    },
}
</script>
