<template>
  <div>
    <div>
      <div class="text-center mb-6 mt-6">
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'clientlist'}">
            Customer list
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'vehicles'}">
            Manage vehicles
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'managepayments'}">
            Manage payments
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'account'}">
            Change password
          </v-btn>
        </div>
        <div class="text-center mb-6">
          <v-btn color="primary" class = "button-fix" 
            :to="{ name: 'users'}">
            Manage app users
          </v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {is_finished, is_paid, topay, share, is_sa} from "../plugins/riviera.js";

export default {

  name: 'Moremenu',
  components: { },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },

  async mounted() {

  },
}
</script>
