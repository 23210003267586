<template>
  <div style="width:92%; margin:auto;">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <div class="mt-3" style="display:flex;align-items:center;">
            <v-btn small outlined class="" color="grey darken-2" @click="setToday">
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <h6 class="mt-2" v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </h6>
            <v-spacer></v-spacer>
            <v-menu small bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-sheet>
        <v-sheet>
          <v-calendar ref="calendar" v-model="focus" color="primary" :events="events"
            :type="type" :event-more="false" @click:event="showEvent" @click:date="viewDay" @change="updateRange">
              <template v-slot:event="{ event }">
                <span class="ml-2 mr-2">{{event.name}}</span>
              </template>
          </v-calendar>
          <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
            <v-card color="grey lighten-4" min-width="320px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon
                  :to="{ name: 'newcontract', params: { clientid: items[selectedEvent.details] ? items[selectedEvent.details].client_id : 0, contract_uuid: selectedEvent.details } }">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteContract(items[selectedEvent.details] ? items[selectedEvent.details] : {})">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon @click="share(items[selectedEvent.details] ? items[selectedEvent.details] : {})">
                  <v-icon>mdi-export-variant</v-icon>
                </v-btn>
                <v-btn icon :to="{ name: 'contractpdf', params: { contract_uuid: selectedEvent.details } }">
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <table>
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="min-width:60px;"><b>From</b></td>
                        <td>{{ selectedEvent.start }}</td>
                      </tr>
                      <tr>
                        <td><b>To</b></td>
                        <td>{{ selectedEvent.end }}</td>
                      </tr>
                      <tr>
                        <td><b>Vehicle</b></td>
                        <td>{{ items[selectedEvent.details] ? items[selectedEvent.details].car.plate + " / " + items[selectedEvent.details].car.brand + " " +  items[selectedEvent.details].car.model: ""}}</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td>{{ items[selectedEvent.details] ? items[selectedEvent.details].total : "0.0" }}</td>
                      </tr>
                      <tr>
                        <td><b>Paid</b></td>
                        <td>{{ items[selectedEvent.details] ? items[selectedEvent.details].amount_paid : "0.0" }}</td>
                      </tr>
                    </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
   <ModalDeleteContract ref="deleteDialog"></ModalDeleteContract>
  </div>
  
</template>


<style>
.v-calendar-weekly {
display: table;
table-layout: fixed;
}
.v-calendar-weekly__week {
height: auto;
display: table-row;
}
.v-calendar-weekly__day {
display: table-cell;
width: calc(100% / 7);
}
.v-calendar-weekly__head-weekday
{
  display:table-cell;
}
.v-calendar-weekly__head
{
  display:table-row;
}
.v-calendar-weekly__day-label
{
  margin-bottom:3px !important; 
}
</style>
<script>
import DataService from "../services/DataService";
import {is_finished, is_paid, topay, getColor, share} from "../plugins/riviera.js";
import ModalDeleteContract from "../components/ModalDeleteContract";
export default {

  name: 'Calendar',
  components: {
    ModalDeleteContract
  },
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    items: {},
    selectedElement: null,
    selectedOpen: false,
    mode: 'stack',
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    events: [],
  }),
  computed: {
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  methods: {
    deleteContract(item)
    {
      this.$refs.deleteDialog.show(item);
    },
    share(item) {
      return share(item);
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    async updateRange({ start, end }) {
      const events = []

      if (localStorage.getItem("password") === null) {
        this.$router.push({ name: 'login' })
      }
      var res = await DataService.getcontracts();
      this.items = res.data.contracts;
      var items = {};
      this.items.forEach(element => {
        items[element.uuid] = element;
      });
      this.items.forEach(element => {
        var real_date_start = new Date(Date.parse(element.date_start));
        real_date_start.setHours(element.time_start.split(':')[0]);
        real_date_start.setMinutes(element.time_start.split(':')[1]);
        var real_date_stop = new Date(Date.parse(element.date_end));
        real_date_stop.setHours(element.time_stop.split(':')[0]);
        real_date_stop.setMinutes(element.time_stop.split(':')[1]);

        events.push(
          {
            name:  (element.car ? element.car.plate: "ND") + " " + element.client.fullname,
            start: real_date_start,
            end: real_date_stop,
            color: getColor(element),
            timed: true,
            details: element.uuid
          }
        )
      });
      this.items = items;
      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
