import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import NewContract from '../views/NewContract.vue';
import Payments from '../views/Payments.vue';
import ClientList from '../views/ClientList.vue';
import ContractList from '../views/ContractList.vue';
import ClientPortal from '../views/ClientPortal.vue';
import Calendar from '../views/Calendar.vue';
import ContractPDF from '../views/ContractPDF.vue';
import Vehicles from '../views/Vehicles.vue';
import Login from '../views/Login.vue';
import Moremenu from '../views/Moremenu.vue';
import ManagePayments from '../views/ManagePayments.vue';
import Account from '../views/Account.vue';
import UserList from '../views/UserList.vue';

Vue.use(Router);

class RouteMeta {
  title: string = 'Riviera Rentals';

  constructor({title}: { title: string }) {
    this.title = title;
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: NewContract,
      meta: new RouteMeta({ title: 'Riviera Rentals - New contract' })
    },
    {
      path:'/login',
      name:'login',
      component: Login,
      meta: new RouteMeta({ title: 'Riviera Rentals - Login' })
    },
    {
      path: '/newcontract/:clientid/:contract_uuid?/:finish?',
      name: 'newcontract',
      component: NewContract,
      meta: new RouteMeta({ title: 'Riviera Rentals - New contract' })
    },
    {
      path: '/payments/v=:value?/c=:currency?/n=:name?/i=:contractid?/g=:guest?/s=:sa?/d=:deposit?/t=:contractnumber?',
      name: 'payments',
      component: Payments,
      meta: new RouteMeta({ title: 'Riviera Rentals - New payment' })
    },
    {
      path: '/contractlist',
      name: 'contractlist',
      component: ContractList,
      meta: new RouteMeta({ title: 'Riviera Rentals - Contracts' })
    },
    {
      path: '/vehicles',
      name: 'vehicles',
      component: Vehicles,
      meta: new RouteMeta({ title: 'Riviera Rentals - Edit vehicles' })
    },
    {
      path: '/clientlist',
      name: 'clientlist',
      component: ClientList,
      meta: new RouteMeta({ title: 'Riviera Rentals - Customers' })
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      meta: new RouteMeta({ title: 'Riviera Rentals - Calendar' })
    },
    {
      path: '/getcontractpdf/:contract_uuid?',
      name: 'contractpdf',
      component: ContractPDF,
      meta: new RouteMeta({ title: 'Riviera Rentals - Contract PDF' })
    },
    {
      path: '/portal/:contract_uuid',
      name: 'portal',
      component: ClientPortal,
      meta: new RouteMeta({ title: 'Riviera Rentals - Portal' })
    },
    {
      path: '/moremenu',
      name: 'moremenu',
      component: Moremenu,
      meta: new RouteMeta({ title: 'Riviera Rentals - More' })
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: new RouteMeta({ title: 'Riviera Rentals - Account' })
    },
    {
      path: '/users',
      name: 'users',
      component: UserList,
      meta: new RouteMeta({ title: 'Riviera Rentals - Users' })
    },
    {
      path: '/managepayments',
      name: 'managepayments',
      component: ManagePayments,
      meta: new RouteMeta({ title: 'Riviera Rentals - Payments' })
    },
  ]
});

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {

  const routeMeta = to.meta as RouteMeta;
  store.dispatch('topToolbar/changeTitle', routeMeta.title);
  next();
});

export default router;