<template>
  <div>
    <div class="text-center mb-6 mt-6" style="width: 95%; margin: auto;">
      <h2 class="mb-6">Change Password</h2>
      <div class="mb-6">{{ error_message }}</div>
      <v-row>
        <v-col>
          <v-form ref="form" v-model="valid" lazy-validation>
            <text-field-outlined v-model="currentPassword" :rules="passwordRules" label="Current Password" type="password"
              required></text-field-outlined>

            <text-field-outlined v-model="newPassword" :rules="passwordRules" label="New Password" type="password"
              required></text-field-outlined>

            <text-field-outlined v-model="confirmPassword" :rules="confirmPasswordRules" label="Confirm New Password"
              type="password" required></text-field-outlined>

            <v-btn color="primary" @click="changePassword" :disabled="!valid">Change Password</v-btn>
          </v-form>

        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>
import DataService from "../services/DataService";
import Vue from 'vue'

export default {
  name: "ChangePassword",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      valid: false,
      error_message: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 6 || "Password must be at least 6 characters",
      ],
    };
  },
  computed: {
    confirmPasswordRules() {
      return [
        (v) => !!v || "Confirmation password is required",
        (v) => v === this.newPassword || "Passwords must match",
      ];
    },
  },
  methods: {
    changePassword() {
      if (this.$refs.form.validate()) {
        // Add the logic to change the password, e.g., an API call
        var to_send = {
          current_password: this.currentPassword,
          new_password_confirmation: this.confirmPassword,
          new_password: this.newPassword,
        };
        DataService.changePassword(to_send).then(
          response => {
            if (!response.data.error) {
              this.error_message = "Password changed successfully";
            } else {
              this.error_message = response.data.message;
            }
          },
        );
        console.log("Password changed successfully");
      }
    },
  },
};
</script>

<style scoped>
.button-fix {
  margin: 10px;
}
</style>
