<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      :items-per-page="25"
      :footer-props="{
        'items-per-page-options': [25, 50, 100, 500, -1]
      }"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :sort-by="['created_at']"
      :sort-desc="[true]"
      mobile-breakpoint="1250"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          class="mx-3"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:[`item.invoice`]="{ item }">
        <v-row class="" style="padding-left:5px;">
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;margin-top:2px;" :to="{ name: 'newcontract',  params: { clientid:item.client_id, contract_uuid: item.uuid }}" small> Edit</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;" @click="editedItem=item;showDeleteDialog(item);" small>Delete</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;"  small :to="{ name: 'contractpdf',  params: { contract_uuid: item.uuid }}">PDF</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;" @click="item.amount_to_pay=topay(item); editedItem=item;payDialog=true;" small>Paid</v-btn>
        </v-row>
        <v-row style="padding-left:5px;">
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;"  small :to="{ name: 'payments',  params: { guest:false, sa:is_sa(item), contractnumber: item.contract_number, currency:item.currency, deposit:0, value:topay(item), name:item.client.firstname+' '+item.client.lastname }}">Pay</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;"  small :to="{ name: 'payments',  params: { guest:false, sa:is_sa(item), contractnumber: item.contract_number, currency:item.currency, deposit:item.deposit_amount, value:item.deposit_amount, name:item.client.firstname+' '+item.client.lastname }}">Deposit</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;" small :to="{ name: 'newcontract',  params: { clientid:item.client_id, contract_uuid: item.uuid,finish:true }}">Finish</v-btn>
          <v-btn class="primary" style="margin-right:3px;margin-top:2px;" small @click="share(item)">Share</v-btn>
        </v-row>

      </template>
      <template v-slot:[`item.total`]="{ item }">
        {{ item.total }} {{ item.currency }}
      </template>
      <template v-slot:[`item.after_extra_total`]="{ item }">
        {{ parseFloat(item.after_extra_total == '' ? 0.0:item.after_extra_total) }} {{ item.currency }}
      </template>
      <template v-slot:[`item.deposit_amount`]="{ item }">
        {{ item.deposit_amount }} {{ item.currency }}
      </template>
      <template v-slot:[`item.topay`]="{ item }">
        {{ topay(item) }} {{ item.currency }}
      </template>
      <template v-slot:[`item.contract_number`]="{ item }">
        <v-chip
          :color="getColor(item)"
          dark
        >
          {{ item.contract_number }}-{{is_sa(item)? "SA":"IN"}}
        </v-chip>
      </template>

    </v-data-table>
    <div class="p-3 text-right"><v-btn class="primary" :loading="more_button_loading" @click="loadmore">Load more...</v-btn></div>
    <v-dialog
      v-model="payDialog"
      width="500"
      fullscreen
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Mark as paid
        </v-card-title>
        <v-card-text class="mt-6">
        <v-form class="mr-4 ml-4">
          <v-row>
                <text-field-outlined>
                      v-model="editedItem.total"
                      label="Rental cost"
                      readonly
                      :suffix="''+editedItem.currency+''"
          </text-field-outlined> 
          </v-row>
          <v-row>
                  <text-field-outlined
                      v-model="editedItem.after_extra_total"
                      label="Extra costs"
                      readonly
                      :suffix="''+editedItem.currency+''"
                  ></text-field-outlined> 
          </v-row>
          <v-row>
                  <text-field-outlined
                      v-model="editedItem.amount_paid"
                      :label="'Paid'"
                      :suffix="''+editedItem.currency+''"
                      readonly
                  ></text-field-outlined> 
          </v-row>     
          <v-row>
                  <text-field-outlined
                  :label="'Total to pay'"
                  v-model="editedItem.amount_to_pay"
                  :suffix="''+editedItem.currency+''"
                  ></text-field-outlined>
          </v-row>         
        </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            
            @click="markAsPayed(editedItem);payDialog = false"
          >
            Mark as paid
          </v-btn>
                    <v-btn
            color="secondary"
            @click="payDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalDeleteContract ref="deleteDialog" @deleted="refresh"></ModalDeleteContract>
  </div>
</template>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {     min-height: 88px;
  height:65px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    height: initial;
    min-height: 28px;

}

.v-btn:not(.v-btn--round).v-size--small
{
  height:25px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.v-data-table__mobile-table-row > td:last-child {
    margin-top:12px;
    margin-bottom:10px;
    padding-bottom:20px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper
{
  padding-left:3px;
  padding-right:3px;
  margin-left:7px;
  margin-right:10px;
}

.v-data-table-header > tr > th
{
  padding-left:3px !important;
  padding-right:3px !important;
}
</style>

<script>
import DataService from "../services/DataService";
import ModalDeleteContract from "../components/ModalDeleteContract"
import {is_finished, is_paid, topay, getColor, share, is_sa} from "../plugins/riviera.js";

export default  {

    name: 'ContractList',
    components: {
      ModalDeleteContract
    },
    data () {
      return {
        search: '',
        payDialog: false,
        items:[],
        editedItem:{},
        more_button_loading:false,
        }
    },
    computed: {
      headers () {
        return [
          { text: 'ID', value: 'contract_number' },
          { text: 'Date', value: 'signature_date' },
          { text: 'First name', value: 'client.firstname' },
          { text: 'Last name', value: 'client.lastname' },
          { text: 'Vehicle', value: 'car.plate' },
          { text: 'Model', value: 'car.model' },
          { text: 'Deposit', value: 'deposit_amount' },
          { text: 'Total', value: 'total' },
          //{ text: 'Extra', value: 'after_extra_total' },
          { text: 'To pay', value:'topay'},
          { text: 'Start', value: 'date_start' },
          { text: 'End', value: 'date_end' },
          { text: 'Invoice', value: 'invoice' },
        ]
      },
    },
    methods: {
      showDeleteDialog(item)
      {
        this.$refs.deleteDialog.show(item);
      },
      getColor(item){
        return getColor(item);
      },
      topay(item)
      {
        return topay(item);
      },
      share(item)
      {
        return share(item);
      },
      is_sa(item)
      {
        return is_sa(item);
      },
      markAsPayed(item)
      {
        DataService.pay(item.uuid, item.amount_to_pay);
      },
      numberFormat(number, width) {
            return new Array(+width + 1 - (number + '').length).join('0') + number;
      },
      filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      },
      async refresh()
      {
        await DataService.getcontracts().then(results => {
          this.items = results.data.contracts;
        })
      },

      async loadmore()
      {
        this.more_button_loading = true;
        await DataService.getcontracts24months().then(results => {
          this.items = results.data.contracts;
          this.more_button_loading = false;
        })
      }
    },

    async mounted()
    {
       this.more_button_loading = true;
        if (localStorage.getItem("password") === null) {
            this.$router.push({ name: 'login' })
        }
        await DataService.getcontracts().then(results => {
          this.items = results.data.contracts;
          this.more_button_loading = false;
        })
    },
}
</script>
