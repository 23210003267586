import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { VTextField, VSelect } from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { VCountrySelect } from '@timbouc/vuetify-country-region-input'

Vue.use(Vuetify);
Vue.component('TextFieldOutlined', {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
})

Vue.component('SelectOutlined', {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
})

Vue.component('CountrySelectOutlined', {
  extends: VCountrySelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  }
})

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#132A46',
          },
        },
      },
});
