import axios from "axios";

const configElement = document.getElementById( 'config' );
const config = JSON.parse( configElement.innerHTML );

let instance =  axios.create({
  baseURL: config.baseUrl,
  headers: {
    "Content-type": "application/json"
  }
});

const MAX_REQUESTS_COUNT = 5
const INTERVAL_MS = 100
let PENDING_REQUESTS = 0

instance.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      } 
    }, INTERVAL_MS)
  })
})


instance.interceptors.response.use(function (response) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.resolve(response)
}, function (error) {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.reject(error)
})


instance.interceptors.request.use((config) => {
  const apiToken = localStorage.getItem('password');
  console.log(apiToken);
  if (apiToken === null)
  {
      return config;
  }
  config.headers = { 'Authorization': "Bearer " + apiToken }
  return config
}, error => {
  return Promise.reject(error)
})

export default instance;

