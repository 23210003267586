
  import { Component, Vue } from 'vue-property-decorator';
  import TopToolbar from './components/TopToolbar.vue';
  import BottomNav from './components/BottomNav.vue';

  @Component({
    components: {
      TopToolbar,
      BottomNav,
    }

  })

  export default class extends Vue {
    public password: string = ''

    public connect()
    {
      console.log("connect")
      if (localStorage.getItem("password") !== null)
        this.password = localStorage.getItem("password")
      else
        this.password = ""
    }

    mounted(): void {
      console.log('mounted')
      this.connect()
    }
  }
