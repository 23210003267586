<template>
  <v-dialog v-model="deleteDialog" width="500" fullscreen>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Delete contract ?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="deleteContract(); deleteDialog = false">
          Delete
        </v-btn>
        <v-btn color="secondary" @click="deleteDialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataService from "../services/DataService";
import { is_finished, is_paid, topay, getColor, share } from "../plugins/riviera.js";

export default {
  name: "ModalDeleteContract",
  data () {
    return {
    deleteDialog: false,
    item: null,
    }
  },
  methods:
  {
    show(item) {
      this.item = item;
      this.deleteDialog = true;
    },
    async deleteContract() {
      DataService.deletecontract(this.item.uuid);
      this.$emit("deleted")
    },
  }
}
</script>