<template>
  <div>
        <v-layout align-center="align-center" justify-center="justify-center">
          <v-flex class="login-form text-xs-center"> 
            <v-card light="light">
              <v-card-text>
                <v-form>
                  <text-field-outlined v-model="user.email" light="light" prepend-icon="email" label="Email" type="email"></text-field-outlined>
                  <text-field-outlined v-model="user.password" light="light" prepend-icon="lock" label="Password" type="password"></text-field-outlined>
                  <v-btn @click="login" block="block" >Sign in</v-btn>
                </v-form>
                <div class="text-center">{{ message }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
    </div>
</template>

<script>
import DataService from "../services/DataService";

export default  {

    name: 'Login',
    components: {
      
    },
    data () {
      return {
      user:{email:'', password:''},
      message:'',
      }
    },
    computed: {

    },
    methods: {
        async login()
        {
            let res = await DataService.login({'email':this.user.email, 'password':this.user.password});
            console.log(res);
            if (res.data.error === false)
            {
                localStorage.setItem('email',res.data.user.email);
                localStorage.setItem('password',res.data.user.password);
                this.$router.push({ name: 'newcontract' })
                this.$emit('toggle', 'login');
            }
            else{
              this.message = "Login error!";
              localStorage.removeItem('email')
              localStorage.removeItem('password')
              this.$emit('toggle', 'unlogin');
            }
        }
    },

    mounted()
    {
    },
}
</script>
