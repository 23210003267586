<template>
        <div>
            <v-data-table 
            dense
            :headers="headers" 
            :items="items" 
            item-key="name" 
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [10, 20, 50, 100, -1]
            }"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Search"
                    append-icon="mdi-magnify"
                    class="mx-3"
                    single-line
                    hide-details
                  ></v-text-field>
                </template>
                <template v-slot:body="{ items, headers }">
                    <tbody>
                        <tr v-for="(item,idx) in items" :key="idx">
                            <td v-for="(header,key) in headers" :key="key">
                                <v-edit-dialog
                                  v-if="header.value !== 'actions'"
                                  :return-value.sync="item[header.value]"
                                  @save="save"
                                  @cancel="cancel"
                                  @open="open"
                                  @close="close"
                                  large
                                > 
                                  <template v-if="header.value === 'hidden'">
                                    {{ item.hidden === 1 ? 'Hidden' : 'Visible' }}
                                    <v-switch
                                      slot="input"
                                      v-model="item.hidden"
                                      :label="item.hidden === 1 ? 'Hidden' : 'Visible'"
                                      :true-value="1"
                                      :false-value="0"
                                    ></v-switch>
                                  </template>

                                  <template v-else>
                                    {{ item[header.value] }}
                                    <v-text-field
                                      slot="input"
                                      v-model="item[header.value]"
                                      label="Edit"
                                      single-line
                                    ></v-text-field>
                                  </template>
                                </v-edit-dialog>
                                <!--<div v-else>
                                    <v-btn small @click="remove(idx)">Delete</v-btn>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <div class="text-center mb-6 mt-6">
                <v-btn class = "button-fix" @click="add" primary>
                    Add new
                </v-btn>
            </div>
            <div class="text-center mb-6 mt-6">
                <v-btn color="primary" class = "button-fix" @click="update">
                    Save changes
                </v-btn>
            </div>
        </div>
        
</template>

<style>

.v-btn:not(.v-btn--round).v-size--small
{
  height:25px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>

<script>
import DataService from "../services/DataService";

export default  {

    name: 'Vehicles',
      data () {
    return {
      headers: [
        {
          text: "Brand",
          align: "left",
          value: "brand",
        },
        { text: "Model", value: "model"},
        { text: "Plate", value: "plate" },
        { text: "Chassis number", value: "chassis", sortable: false, },
        { text: "Hidden", value: "hidden"},
        /*{ text: 'Action', value: 'actions', active: true, sortable: false}*/
      ],
      items: [],
      search: ''
    }
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      },
    save() {},
    remove(idx) {

        DataService.deletecar(this.items[idx].id).then (
          response => {
            console.log("remove idx " + idx)
            this.items.splice(idx,1);
          });

    },
    cancel() {},
    open() {},
    close() {},
    add() {
        var last = this.items.slice(-1)
        console.log(last)
        var new_number = last[0]['id'] + 1
        this.items.push({id:new_number, model:"Model", plate:"VSXXXXXX", chassis:"WD XXX", brand:"New"})
    },
    update()
    {
      DataService.updatecars({cars:this.items})
      .then(response => {
        this.items = [];
        for (var i in response.data['cars']) {
           this.items.push(response.data['cars'][i])
        }
      });
    }
  },
  mounted(){
    DataService.getcars()
      .then(response => {
        for (var i in response.data['cars']) {
           this.items.push(response.data['cars'][i])
        }
      });
  }
}
</script>