<template>
  <div>
    <v-alert v-if="error_message.length > 0" type="error" class="text-center">{{  error_message  }}</v-alert>
    <v-data-table dense :headers="headers" :items="items" item-key="name" :items-per-page="50" :footer-props="{
      'items-per-page-options': [10, 50, 100, 500, -1]
    }" class="elevation-1" :search="search" :custom-filter="filterOnlyCapsText">
      <template v-slot:top>
        <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" class="mx-3" single-line
          hide-details></v-text-field>
      </template>
      <template v-slot:body="{ items, headers }">
        <tbody>
          <tr v-for="(item, idx) in items" :key="idx">
            <td v-for="(header, key) in headers" :key="key">
              <v-edit-dialog v-if="['name', 'email', 'password', 'admin_level'].indexOf(header.value) > -1" :return-value.sync="item[header.value]"
                @save="() => update(idx)" @cancel="cancel" @open="open" @close="close" large>

                <template v-if="header.value === 'password'">
                  {{ '*******' }}
                  <v-text-field
                    slot="input"
                    v-model="item.password"
                    label="Edit"
                    type="password"
                    single-line
                  ></v-text-field>
                </template>

                <template v-else-if="header.value === 'admin_level'">
                  {{ item.admin_level === 1 ? 'Admin' : 'User' }}
                  <v-switch
                    slot="input"
                    v-model="item.admin_level"
                    :label="item.admin_level === 1 ? 'Admin' : 'User'"
                    :true-value="1"
                    :false-value="0"
                  ></v-switch>
                </template>

                <template v-else>
                  {{ item[header.value] }}
                  <v-text-field
                    slot="input"
                    v-model="item[header.value]"
                    label="Edit"
                    single-line
                  ></v-text-field>
                </template>
              </v-edit-dialog>
              <div v-else-if="header.value === 'actions'">
                <v-btn small @click="remove(idx)">Delete</v-btn>
              </div>
              <div v-else>
                {{ item[header.value] }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div class="text-center mb-6 mt-6">
      <v-btn class="button-fix" @click="dialog=true" primary>
        Add new
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Add New User</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="newUser.name" label="Name" :rules="[rules.required]" required></v-text-field>

            <v-text-field v-model="newUser.email" label="Email" :rules="[rules.required, rules.email]"
              required></v-text-field>

            <v-text-field v-model="newUser.password" label="Password" type="password"
              :rules="[rules.required, rules.min]" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="addNewUser">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<style>
.v-btn:not(.v-btn--round).v-size--small {
  height: 25px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>

<script>
import DataService from "../services/DataService";

export default {

  name: 'UserList',
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Password", value: "password", sortable: false }, 
        { text: "Admin", value: "admin_level"},
        { text: "Last login", value: "last_login" },
        { text: 'Action', value: 'actions', active: true, sortable: false }
      ],
      items: [],
      dialog: false,
      valid: false,
      error_message: "",
      newUser: {
        name: "",
        email: "",
        password: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "E-mail must be valid.",
        min: (value) => value.length >= 6 || "Password must be at least 6 characters.",
      },
      search: ''
    }
  },
  methods: {
    filterOnlyCapsText(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
    remove(idx) {

      DataService.delUser(this.items[idx].id).then(
        response => {
          console.log("remove idx " + idx)
          this.items.splice(idx, 1);
        });

    },
    cancel() { },
    open() { },
    close() { },
    addNewUser() {
      if (this.$refs.form.validate()) {
        // Call the API to save the new user
        DataService.addUser(this.newUser).then((response) => {
          // Reload or update the table with the new user data
          this.items.push(response.data.user);
          this.dialog = false; // Close the dialog
          this.resetNewUser();
        });
      }
    },
    resetNewUser() {
      this.newUser = {
        name: "",
        email: "",
        password: "",
      };
    },
    update(idx) {
      DataService.updateUser(this.items[idx])
      .then(response => {
        // Optional: You can handle response if needed
        console.log('User updated successfully:', response.data);
      })
      .catch(error => {
        // Handle error if the update fails
        console.error('Failed to update user:', error);
        this.error_message = 'Failed to update user';
      });
    }
  },
  mounted() {
    DataService.getUsers()
      .then(response => {
        if (response.data.error)
         {
          console.error('Failed to fetch users:', response.data.error);
          this.error_message = response.data.message;
          return;
         } else
            this.items = response.data;
      });
  }
}
</script>