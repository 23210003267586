<template>
  <div>
    <div class="mt-3 mb-3 ml-3 mr-3">
      <span v-for="(value,  n, index) in   all_payments  " :key="index">
        <v-btn @click="payments = all_payments[n]; name = n; loaded = true">{{ n }}</v-btn>
      </span>
    </div>
    <v-alert v-if="error_message.length > 0" type="error">{{ error_message }}</v-alert>

    <div v-if="loaded">
      <v-data-table dense :headers="headers" :items="payments" :items-per-page="50" :footer-props="{
        'items-per-page-options': [10, 50, 100, 500, -1]
      }" class="elevation-1" :search="search">
        <template v-slot:top>
          <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" class="mx-3" single-line
            hide-details></v-text-field>
        </template>
        <template v-slot:item.created="{ item }">
          <span>{{ new Date(item.created * 1000).toLocaleString() }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span>{{ (item.amount / 100.0).toFixed(2) }} {{ item.currency.toUpperCase() }}</span>
        </template>
        <template v-slot:item.amount_received="{ item }">
          <span>{{ (item.amount_received / 100.0).toFixed(2) }} {{ item.currency.toUpperCase() }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item)" dark>{{ translateStatus(item) }}
          </v-chip>
        </template> <template v-slot:item.actions="{ item }">
          <v-btn small v-if="item.status === 'requires_capture'"
            @click="edit_paymentintent = item; capture_amount = item.amount / 100.0; modal_capture = true">
            Capture
          </v-btn>
          <v-btn small v-if="item.status === 'requires_capture'"
            @click="edit_paymentintent = item; modal_cancel = true">
            Cancel
          </v-btn>
          <v-btn small v-if="item.status === 'succeeded' || item.status === 'requires_capture' || item.status === 'canceled'"
            @click="edit_paymentintent = item; modal_charge = true">
            Charge
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-row><v-col>Loading...</v-col></v-row>
    </div>
    <v-dialog v-model="modal_capture" width="400">
      <v-card>
        <v-card-title class="text-h5">Capture transaction</v-card-title>

        <v-card-text>
          <v-alert v-if="error_message.length > 0" type="error">{{ error_message }}</v-alert>
          Capture amount:
          <text-field-outlined v-model="capture_amount" :max="edit_paymentintent.amount / 100.0"
            :suffix="edit_paymentintent.currency ? edit_paymentintent.currency.toUpperCase() : ''" :min="1" type="number"></text-field-outlined>
          Warning: capturing a transaction is irreversible.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modal_capture = false"> Exit </v-btn>
          <v-btn color="red" text @click="capture();"> Capture </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal_charge" width="400">
      <v-card>
        <v-card-title class="text-h5">Charge new amount</v-card-title>

        <v-card-text>
          <v-alert v-if="error_message.length > 0" type="error">{{ error_message }}</v-alert>
          Charge amount:
          <text-field-outlined v-model="charge_amount" :max="edit_paymentintent.amount / 100.0"
            :suffix="edit_paymentintent.currency ? edit_paymentintent.currency.toUpperCase() : ''" :min="1" type="number"></text-field-outlined>
          Deposit:
          <v-switch v-model="charge_deposit" label="Deposit"></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modal_charge = false"> Exit </v-btn>
          <v-btn color="red" text @click="charge();"> Charge </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal_cancel" width="400">
      <v-card>
        <v-card-title class="text-h5">Cancel transaction</v-card-title>

        <v-card-text>
          <v-alert v-if="error_message.length > 0" type="error">{{ error_message }}</v-alert>
          Cancelled amount: {{ edit_paymentintent.amount / 100.0 }} {{ edit_paymentintent.currency ? edit_paymentintent.currency.toUpperCase() : '' }}
          <br />
          <div v-if="edit_paymentintent.customer">Customer: {{ edit_paymentintent.customer.name }}</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modal_cancel = false"> Exit </v-btn>
          <v-btn color="red" text @click="cancel()"> Cancel transaction </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.v-btn:not(.v-btn--round).v-size--small {
  height: 25px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>

<script>
import DataService from "../services/DataService";

export default {

  name: 'Payments',
  data() {
    return {
      headers: [
        {
          text: "Date",
          align: "left",
          value: "created",
          dataType: "Date"
        },
        { text: "Amount", value: "amount" },
        { text: "Captured", value: "amount_received" },
        { text: "Customer", value: "customer.name" },
        { text: "Status", value: "status" },
        { text: 'Action', value: 'actions', active: true, sortable: false }
      ],
      all_payments: {},
      search: '',
      payments: "",
      edit_paymentintent: {},
      modal_capture: false,
      modal_cancel: false,
      capture_amount: 0,
      charge_amount: 0,
      modal_charge: false,
      charge_deposit: true,
      loaded: false,
      name: "IN",
      error_message: '',
    }
  },
  methods: {
    getColor(item) {
      switch (item.status) {
        case 'succeeded':
          return 'green';
        case 'requires_capture':
          return 'orange';
        case 'failed':
        case 'requires_payment_method':
        case 'canceled':
          return 'red';
      }
    },
    translateStatus(item) {
      if (item.status === "requires_payment_method") {
        return "incomplete";
      }
      if (item.status === "requires_capture") {
        let expiration = new Date(new Date(item.created * 1000).getTime() + (6.5 * 24 * 60 * 60 * 1000)).toLocaleString();
        return "to capture until " + expiration
      }
      return item.status;
    },
    charge() {
      this.error_message = '';
      let to_send = {
        amount: this.charge_amount,
        id: this.edit_paymentintent.id,
        is_sa: this.name.includes("SA"),
        deposit: this.charge_deposit
      }
      DataService.paymentCharge(to_send).then(
        response => {
          if (!response.data[0].error) {
            this.modal_charge = false;
            setTimeout(() => {
              this.refresh();
            }, 2000);
          }
          this.error_message = response.data[0].message;
        });
    },
    capture() {
      this.error_message = '';
      let to_send = {
        amount: this.capture_amount * 100,
        id: this.edit_paymentintent.id,
        is_sa: this.name.includes("SA")
      }
      DataService.paymentCapture(to_send).then(
        response => {
          if (!response.data.error) {
            this.modal_capture = false;
            setTimeout(() => {
              this.refresh();
            }, 2000);
          }
          this.error_message = response.data.message;
        });
    },
    cancel() {
      this.error_message = '';
      let to_send = {
        id: this.edit_paymentintent.id,
        is_sa: this.name.includes("SA")
      }
      DataService.paymentCancel(to_send).then(
        response => {
          if (!response.data.error) {
            this.modal_cancel = false;
            setTimeout(() => {
              this.refresh();
            }, 2000);
          }
          this.error_message = response.data.message;
        });
    },
    refresh() {
      this.error_message ='';
      this.loaded = false;
      DataService.getpayments()
        .then(response => {
          this.all_payments = response.data;
          this.loaded = true;

          this.payments = this.all_payments[this.name]
        });
    }
  },
    mounted() {
      this.name = "IN";
      this.refresh();
    }
  }
  
</script>